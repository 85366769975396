<div [class.popin]="data.popin || withDetails">
  <div class="product" *ngIf="product && product.selectedDeclination">
    <div class="row">
      <div class="col-md-4">
        <div class="product-image">
          <div *ngIf="withDetails && productImage" class="img-zoom-wrapper">
<!--            <ngx-img-zoom class="ngx-img-zoom" [previewImageSrc]="productImage.url" [zoomImageSrc]="product.getHdImageUrl(productImage.url)"-->
<!--                          [enableZoom]="true" [imgStyle]="'width:100%; float:left;'" [resultStyle]="'margin-left:320px; position:absolute; z-index:999; display: block !important'"-->
<!--                          [containerStyle]="'left: 5px; top: 25%; max-width: calc(100% - 10px);'"></ngx-img-zoom>-->
            <lib-ngx-image-zoom
              [thumbImage]=productImage.url
              [fullImage]=product.getHdImageUrl(productImage.url)
              [enableScrollZoom]="true"
              [enableLens]="true"
              [lensWidth]="150"
              [lensHeight]="150"
            ></lib-ngx-image-zoom>
          </div>
          <img *ngIf="!withDetails && productImage && !data.popin" src="{{ productImage.url }}" alt="{{ product.name }}" (click)="googleAnalyticsService.selectItem(product)" class="link" [routerLink]="['/classeur/fiche-produit', product.id]">
          <img *ngIf="!withDetails && productImage && data.popin" src="{{ productImage.url }}" alt="{{ product.name }}">
          <div class="number-photos" *ngIf="this.product.selectedDeclination.images?.length > 1">{{ product.selectedDeclination.images?.length }} <mat-icon class="font-size">photo_camera</mat-icon></div>
          <div class="fullscreen" *ngIf="productImage"><button mat-icon-button (click)="showFullscreen(product.getHdImageUrl(productImage.url))"><mat-icon>fullscreen</mat-icon></button></div>
          <button mat-icon-button class="previous-image" (click)="selectPreviousImage()" *ngIf="this.product.selectedDeclination.images?.length > 1"><mat-icon>navigate_before</mat-icon></button>
          <button mat-icon-button class="next-image" (click)="selectNextImage()" *ngIf="this.product.selectedDeclination.images?.length > 1"><mat-icon>navigate_next</mat-icon></button>
          <div class="clear"></div>
        </div>
        <div class="product-tags" *ngIf="product.tags">
          <div class="tag" *ngIf="product.hasTag('renewable')">Rec</div>
          <div class="tag" *ngIf="product.hasTag('innovation')"><mat-icon aria-hidden="false" title="Innovation" aria-label="Innovation" class="font-size">lightbulb_outline</mat-icon></div>
          <div class="tag" *ngIf="product.hasTag('new')">New</div>
          <div class="tag must-have" *ngIf="product.hasTag('must_have')"><mat-icon aria-hidden="false" title="Incontournable" aria-label="Must have" class="font-size">star</mat-icon></div>
          <div class="tag custom" *ngIf="product.hasTag('press_kit')"><img alt="Dossier de presse" title="Dossier de presse" src="/assets/images/dossier_de_presse.png"/></div>
          <div class="tag custom" *ngIf="product.hasTag('essential')"><img alt="Essentiel" title="Essentiel" src="/assets/images/essentiel.svg"/></div>
<!--          <div class="tag custom" *ngIf="product.hasTag('CLV')"><img alt="CLV" title="Communication Lieu de Vente" src="/assets/images/clv.svg"></div>-->
          <div class="tag custom" *ngIf="product.hasTag('CLV')"><img alt="TOTEM" title="TOTEM" src="/assets/images/totem.png"></div>
          <div class="tag custom" *ngIf="product.hasTag('comfy')"><img alt="Confortable" title="Confortable" src="/assets/images/comfy.png"></div>
          <div class="tag custom" *ngIf="product.hasTag('ultra_soft')"><img alt="Ultra doux" title="Ultra doux" src="/assets/images/ultra_soft.png"></div>
          <div class="tag custom" *ngIf="product.hasTag('CLV')"><img alt="TOTEM" title="TOTEM" src="/assets/images/totem.png"></div>
          <div class="tag custom" *ngIf="product.hasTag('blogger')"><img alt="Bloggeuse" title="Bloggeuse" src="/assets/images/bloggeuse.png"></div>
          <div class="tag custom" *ngIf="product.hasTag('heart_stroke')"><img alt="Coup de coeur" title="Coup de coeur" src="/assets/images/coup-de-coeur.jpg"></div>
          <div class="tag custom" *ngIf="product.hasTag('dressing')"><img alt="Affiche dressing" title="Affiche dressing" src="/assets/images/dressing.png"></div>
          <div class="tag custom" *ngIf="product.hasTag('big_size')"><img alt="Existe en grande taille" title="Existe en grande taille" src="/assets/images/big_size.png"></div>
        </div>
        <ng-container *ngIf="withDetails && product.selectedDeclination.images && product.selectedDeclination.images.length > 0">
          <img *ngFor="let image of product.selectedDeclination.images" src="{{ image.url }}" alt="{{ product.name }}" class="small-image" (click)="productImage = image">
        </ng-container>
        <ng-container *ngIf="withDetails && product.properties.url_video && product.properties.url_video.length > 0">
          <a *ngFor="let video of product.properties.url_video" class="small-image video" [href]="video" target="_blank"><img src="/assets/images/youtube.svg" alt="logo youtube"></a>
        </ng-container>
      </div>
      <div class="col-md-8">
        <img *ngIf="product.brand?.urlImage" src="{{ product.brand?.urlImage }}" alt="{{ product.brand.name }}" class="brand">
        <div *ngIf="product.brand && !product.brand.urlImage" class="brand">{{ product.brand.name }}</div>
        <h3 *ngIf="!withDetails && !data.popin" (click)="googleAnalyticsService.selectItem(product)" class="link" [routerLink]="['/classeur/fiche-produit', product.id]">{{ product.name }}</h3>
        <h3 *ngIf="withDetails || data.popin">{{ product.name }}</h3>
        <div *ngIf="!isWishlist">N° {{ product.selectedDeclination.reference }}</div>
        <div *ngIf="isWishlist"><ng-container *ngIf="product.properties['theme_label']?.length > 0">{{ product.properties['theme_label'][0].replace('THEME ', '') }} - </ng-container>N° de présentation {{ product.selectedDeclination.productVariants[0]?.sku }}</div>
        <div class="mb10"></div>

        <div class="row description">
          <div class="col-md-12">
            <ul>
              <li *ngIf="product.properties?.frequency">{{ product.properties?.frequency?.toString() }}</li>
              <li *ngIf="product.rangeCodes">À partir de {{ product.rangeCodes[0] }}</li>
            </ul>
          </div>
        </div>

        <div class="row infos">
          <div class="col-md-3 conditionning">Cdt : <strong class="accent">{{ product.salesUnitCount }}</strong></div>
          <div class="col-md-3 price text-center no-gutters">Px Cession HT <div class="value large" [innerHtml]="product?.selectedDeclination?.price?.purchasingPrice | price"></div></div>
          <div class="col-md-3 price text-center no-gutters">PV TTC <div class="value large" [innerHtml]="product.selectedDeclination.price?.normalPrice | price"></div>
            <div class="mt5" *ngIf="withDetails && sharedVariablesService.saleChannel.type === 'bazar'">
              <span class="light">Marché T1/T2/T3 : </span><span class="value">{{ product.properties.price_t1 }}</span>/<span class="value">{{ product.properties.price_t2 }}</span>/<span class="value">{{ product.properties.price_t3 }}</span>
            </div>
          </div>
          <div class="col-md-3 light text-center no-gutters">
            Tx marge brute <br>{{ product.selectedDeclination?.price?.marginRate | percent:'1.0-2' }}
          </div>
        </div>

        <div class="row mt10">
          <div class="col-md-12 colors-wrapper" *ngIf="!isWishlist">
            <div class="colors-header" [class.hideStock]="sharedVariablesService.saleChannel.type !== 'capsule'">
              <div>COULEURS :</div>
              <div class="stocks">STOCKS :</div>
            </div>
            <div class="colors">
              <ul>
                <li *ngFor="let declination of product.productDeclinations"
                    [ngClass]="{ 'active' :declination.id === product.selectedDeclination.id, 'noStock': sharedVariablesService.saleChannel.type !== 'capsule' }">
                  <div class="image" (click)="chooseDeclination(declination)" title="{{ declination.value }}">
                    <img src="{{ declination.properties?.color_image_url && declination.properties.color_image_url.length > 0 ? declination.properties.color_image_url[0] : '' }}" alt="{{ declination.value }}">
                  </div>
                  <div class="color-name">
                    {{ declination.value }}
                  </div>
                  <div class="color-stock" *ngIf="sharedVariablesService.saleChannel.type === 'capsule' && declination.productVariants.length > 0">
                    {{ declination.productVariants[0].stock }}
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-md-12" *ngIf="sharedVariablesService.saleChannel.type === 'FOND_DE_RAYON'">
            <div>COLORIS DANS LE COLIS : <strong class="mr10" *ngFor="let declination of product.productDeclinations">{{ declination.value }}</strong></div>
            <div>COURBE DE TAILLES : <strong class="mr10" *ngFor="let variant of product.selectedDeclination.productVariants">{{ variant.value }}</strong></div>
          </div>
          <div class="col-md-12" *ngIf="sharedVariablesService.saleChannel.type === 'bazar'">
            <div *ngIf="product.properties.range_recommendation">Préco. gamme/vagues de livraison : <strong>{{ product.properties.range_recommendation }}</strong></div>
            <div *ngIf="product.properties.order_recommendation">Préco. de commande (colis par niveau HU2&gt;SU2) : <strong>{{ product.properties.order_recommendation }}</strong></div>
            <div *ngIf="product.properties.range_name">Nbre éléments / Niveau tract : <strong>{{ product.properties.range_name }}</strong></div>
          </div>
          <div class="col-md-12" *ngIf="sharedVariablesService.saleChannel.type !== 'bazar'">
            <div *ngIf="product.properties.selling_point || product.properties.selling_point_details">{{ product.properties.selling_point?.join(' - ') }} {{ product.properties.selling_point_details?.join(' - ') }}</div>
            <div *ngIf="product.properties.details_care_instructions || product.properties.washing_advice">
              <span *ngIf="product.properties.details_care_instructions">{{ product.properties.details_care_instructions?.join(', ') }}</span>
              <span *ngIf="product.properties.washing_advice">{{ product.properties.washing_advice?.join(', ') }}</span>
            </div>
          </div>
        </div>

        <form [formGroup]="quantitiesForm" (ngSubmit)="submitQuantities()">
          <div class="mt10 mb10 quantities" [ngClass]="{ 'no-label': isWishlist, 'row': !isWishlist }" *ngIf="product.selectedDeclination">
            <mat-form-field appearance="fill" *ngFor="let variant of product.selectedDeclination.productVariants">
              <mat-label *ngIf="!isWishlist">{{ variant.value }}</mat-label>
              <input matInput [formControlName]="variant.ean">
              <mat-hint>Colis</mat-hint>
            </mat-form-field>
          </div>

          <div class="row mb5">
            <div class="col-md-4 totals" *ngIf="!isWishlist">
              <div class="flex">TOTAL COLIS : <strong class="accent">{{ countPackages() }}</strong></div>
              <div class="flex">TOTAL UVC : <strong>{{ countPackages() * product.salesUnitCount }}</strong></div>
            </div>
            <div class="col-md-6 pr0" [ngClass]="{ 'mt20': isWishlist }" *ngIf="!transactionIsClosed && sharedVariablesService.saleChannel.isOpen(true)">
              <button type="submit" mat-raised-button color="accent" *ngIf="!isAlreadyInTransaction"
                      [disabled]="(sharedVariablesService.saleChannel.type !== 'FOND_DE_RAYON' && quantitiesForm.pristine) || !quantitiesForm.valid || loading ||
                      (sharedVariablesService.saleChannel.type === 'capsule' && product.selectedDeclination.productVariants[0].stock === 0)"
                      [class.spinner]="loading">
                <mat-icon aria-hidden="false" aria-label="Panier">add_shopping_cart</mat-icon>
                {{ (sharedVariablesService.saleChannel.type === 'capsule' && product.selectedDeclination.productVariants[0].stock === 0) ? 'INDISPONIBLE' : 'AJOUTER ' + cartLabelAdd }}
              </button>
              <button type="submit" mat-raised-button color="primary" *ngIf="isAlreadyInTransaction"
                      [disabled]="quantitiesForm.pristine || !quantitiesForm.valid || loading ||
                      (sharedVariablesService.saleChannel.type === 'capsule' && product.selectedDeclination.productVariants[0].stock === 0 && !this.sharedVariablesService.transaction.itemsAsObject[product.id][product.selectedDeclination.productVariants[0].ean])"
                      [class.spinner]="loading">
                <mat-icon aria-hidden="false" aria-label="Panier">shopping_cart</mat-icon>
                {{ (sharedVariablesService.saleChannel.type === 'capsule' && product.selectedDeclination.productVariants[0].stock === 0 && !this.sharedVariablesService.transaction.itemsAsObject[product.id][product.selectedDeclination.productVariants[0].ean]) ? 'INDISPONIBLE' : 'MODIFIER ' + cartLabelEdit }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <mat-tab-group class="features-tabs mt20" *ngIf="withDetails && product.properties" animationDuration="0ms" (selectedTabChange)="changedTab($event)">
      <mat-tab label="Description" *ngIf="product.description || product.properties.description_details">
        <p>{{ product.description }}</p>
        <ul *ngIf="product.properties.description_details" class="mt10">
          <li *ngFor="let detail of product.properties.description_details">{{ detail }}</li>
        </ul>
      </mat-tab>
      <mat-tab *ngIf="product.properties.details_care_instructions || product.properties.washing_advice" label="Composition & Entretien">
        <ul class="mt10">
          <li *ngFor="let detail of product.properties.details_care_instructions">{{ detail }}</li>
        </ul>
        <ul>
          <li *ngFor="let detail of product.properties.washing_advice">{{ detail }}</li>
        </ul>
      </mat-tab>
      <mat-tab *ngIf="product.properties.technical_specifications" label="Caractéristiques Techniques">
        <ul class="mt10">
          <li *ngFor="let detail of product.properties.technical_specifications">{{ detail }}</li>
        </ul>
      </mat-tab>
      <mat-tab *ngIf="product.properties.selling_point || product.properties.selling_point_details" label="Argumentaire Commercial">
        <ul class="mt10">
          <li *ngFor="let detail of product.properties.selling_point">{{ detail }}</li>
        </ul>
        <ul>
          <li *ngFor="let detail of product.properties.selling_point_details">{{ detail }}</li>
        </ul>
      </mat-tab>
      <mat-tab *ngIf="product.properties.divers" label="Divers">
        <ul class="mt10">
          <li *ngFor="let detail of product.properties.divers">{{ detail }}</li>
        </ul>
      </mat-tab>
    </mat-tab-group>
    <div *ngIf="withDetails && product.selectedDeclination.relatedDeclinations.length > 0">
      <h2>Produits complémentaires</h2>
      <div class="row">
        <div class="col-md-3" *ngFor="let relatedDeclination of product.selectedDeclination.relatedDeclinations">
          <app-product-item-small [productId]="relatedDeclination.productId" [declinationId]="relatedDeclination.id"></app-product-item-small>
        </div>
      </div>
    </div>
  </div>
  <ngx-ui-loader *ngIf="this.data?.popin" [loaderId]="'loader-product-popin'"></ngx-ui-loader>
</div>
<div class="fullscreen-image" *ngIf="fullscreenImgSrc" [ngClass]="{ show: fullscreenImgSrc }">
  <div class="text-right">
    <button mat-icon-button (click)="hideFullscreen()"><mat-icon>fullscreen_exit</mat-icon></button>
  </div>
  <img [src]="fullscreenImgSrc" (click)="hideFullscreen()" alt="Image agrandie"/>
  <button mat-icon-button class="previous-image" (click)="selectPreviousImage()" *ngIf="this.product.selectedDeclination.images?.length > 1"><mat-icon>navigate_before</mat-icon></button>
  <button mat-icon-button class="next-image" (click)="selectNextImage()" *ngIf="this.product.selectedDeclination.images?.length > 1"><mat-icon>navigate_next</mat-icon></button>
  <div class="text-center mt10">Photo {{ imageIndex + 1 }} / {{ this.product.selectedDeclination.images?.length }}</div>
</div>
